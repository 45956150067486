import React from 'react'

import styles from './style.module.scss'

import { get } from 'lodash-es'

import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

import Button from '../button'

import DefaultThumbnail from '../single-project/default-thumbnail'

export default () => {
    const { allWordpressWpMarketResearch } = useStaticQuery(graphql`
        {
            allWordpressWpMarketResearch(sort: { order: DESC, fields: date }) {
                nodes {
                    title
                    content
                    acf {
                        report_pdf_file {
                            link
                        }
                        logo {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <div className={styles.marketReports}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>Market research</h1>
                        {allWordpressWpMarketResearch.nodes.map(report => {
                            const img = get(
                                report,
                                'acf.logo.localFile.childImageSharp.fluid'
                            )

                            return (
                                <div className={styles.reportBlock}>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-2">
                                            {img ? (
                                                <Img
                                                    className={styles.logo}
                                                    fluid={img}
                                                />
                                            ) : (
                                                <DefaultThumbnail />
                                            )}
                                        </div>
                                        <div className="col-xs-12 col-md-10">
                                            <h2>{report.title}</h2>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: report.content
                                                }}
                                            />
                                            <Button
                                                link={
                                                    report.acf.report_pdf_file
                                                        .link
                                                }
                                            >
                                                Download now
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        }
                        <div className={styles.poweredBy}>
                            <div className="row">
                                <div className="col-xs-4 col-sm-2 "></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
