import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import MarketResearchSections from '../components/market-research-sections'

export default ({ data }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Market research'
                }
            ]}
            marketTicker
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <MarketResearchSections />
        </Layout>
    )
}

export const query = graphql`
    {
        allWordpressPage(filter: { slug: { eq: "market-research" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
